@import "../../../../styles/variables";

.sectionHeading {
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  text-transform: uppercase;
}

.sectionSubHeading {
      font-weight: 700;
      font-size: 24px;
      text-transform: uppercase;
}
.headerstyle {
  width: 100%;
  max-width: 1370px;
  margin: auto;
  text-align: center;
}

.formmainContainer {
  position: relative;

  .formrightshadow {
    position: absolute;
    right: 0;
    top: 50px;
    z-index: -1;
  }
}

.formContainer {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  display: flex;

  .form_1x {
    width: 30%;
    padding: 15px 0;
    background-color: $white;

    .form_x1 {
      padding: 0px 20px;
      .formuploadheading {
        display: flex;
        align-items: center;
        gap: 15px;
        width: 100%;

        .formheading {
          font-size: 17px;
          font-weight: 500;
        }
        .questionIcon {
          width: 15px;
          height: 15px;
        }
      }
      .formuploadfile {
        border: 2px dashed $primary-2;
        border-radius: 10px;
        height: 169px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;

        .formuploadICon {
          width: 45px;
          height: 45px;
        }
      }

      //     Delete & Edit Btns
      .formdeleteeditBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 20px 0;
        .formdeleteBtn {
          border: 1px solid $primary-2;
          background-color: $secondary-btn-bg;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          padding: 5px;
          font-size: 14px;
          font-weight: 500;
          height: 38px;
          width: 115px;

          .formdeleteIcon {
            background-color: $primary-2;
            border-radius: 50px;
            width: 20px;
            height: 20px;
            font-size: 15px;
            padding: 3px;
            color: $white;
          }
        }
      }

      //     Genereted Btn
      .formgenerBtn {
        padding: 0 30px;
        height: 43px;
        background-color: $tertiary-border;
        border-radius: 50px;
        color: $white;
        text-align: center;
        font-weight: 600;
        font-size: 15px;
        border: none;
        cursor: pointer;
        margin: 0 auto;
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .form_x2 {
      border-top: 1px solid $secondary-btn-bg;
      padding: 20px;

      .formtheme_xx1 {
        border: 1px solid $secondary-btn-bg;
        height: 45px;

        .formtheme {
          display: flex;
          align-items: center;
          background-color: $white;
          padding: 0 7px;
          font-size: 15px;
          font-weight: 500;
          gap: 10px;
          margin-top: -10px;
          margin-left: 11px;

          .formupArrow {
            background-color: $primary;
            width: 15px;
            height: 15px;
            font-size: 12px;
            padding: 1px;
            border-radius: 100%;
            color: $white;
          }
        }
        .formmoderndrop {
          width: 90%;
          margin: auto;
          position: relative;
          margin-top: 15px;

          .formDropdown_x1 {
            width: 100%;
            height: 34px;
            background-color: $secondary;
            border-radius: 10px;
            padding: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            margin: auto;
            cursor: pointer;
          }
          .formsmothdrop {
            position: absolute;
            top: 40px;
            background-color: $white;
            z-index: 10;
            width: 100%;
            border-radius: 10px;
            padding: 5px 15px;
            font-size: 12px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            gap: 5px;
            box-shadow: $medium-shadow;
          }
        }
      }

      .formtheme_xx2 {
        border: 1px solid $secondary-btn-bg;
        position: relative;
        margin-top: 50px;
        padding: 10px 16px;

        .formtheme {
          display: flex;
          align-items: center;
          background-color: $white;
          padding: 0 7px;
          font-size: 15px;
          font-weight: 500;
          gap: 10px;
          position: absolute;
          top: -13px;
          left: 10px;

          .formupArrow {
            background-color: $primary;
            width: 15px;
            height: 15px;
            font-size: 12px;
            padding: 1px;
            border-radius: 100%;
            color: $white;
          }
        }

        .formDropdown_x1 {
          width: 100%;
          height: 34px;
          background-color: $secondary;
          border-radius: 10px;
          padding: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          cursor: pointer;
          margin: auto;
          margin-top: 10px;
        }

        .formsqft {
          background-color: $secondary;
          border-radius: 10px;
          padding: 6px 7px;
          display: flex;
          align-items: center;
          gap: 10px;
          height: 34px;
          font-size: 12px;
          margin-top: 15px;

          :nth-child(1) {
            background-color: $white;
            padding: 2px;
            border-radius: 8px;
            width: 37px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          :nth-child(2) {
            opacity: 0.5;
          }
        }

        .formporch {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 15px;
          margin: 15px 0;

          .formpodrop {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $secondary;
            height: 34px;
            border-radius: 10px;
            padding: 0 7px;
            font-size: 12px;
            cursor: pointer;
          }
          .formpornumber {
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $secondary;
            height: 34px;
            border-radius: 10px;
            padding: 0 7px;
            font-size: 12px;
          }
        }

        .formtext {
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.25px;
          margin-bottom: 20px;
        }

        .formuploadfile {
          background-color: $tertiary-border;
          border-radius: 36px;
          color: $white;
          font-size: 11px;
          font-weight: 600;
          padding: 0px 10px;
          height: 29px;
          width: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: absolute;
          left: 16px;
          bottom: -14px;

          .formlabel {
            display: flex;
            align-items: center;
            gap: 5px;
            justify-content: center;
            text-align: center;
            cursor: pointer;

            .formuploadICon {
              background-color: $white;
              border-radius: 100px;
              width: 18px;
              height: 18px;
              color: $black;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }

    .form_x3 {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 20px;

      :last-child {
        border-bottom: none;
      }

      .formgenerBtn {
        padding: 0 20px;
        height: 43px;
        background-color: $tertiary-border;
        border-radius: 50px;
        color: $white;
        text-align: center;
        font-weight: 600;
        font-size: 15px;
        border: none;
        cursor: pointer;
        margin: 0 auto;
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
      }
      .formlists {
        :last-child {
          border-bottom: none;
        }

        .formlist {
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 500;
          font-size: 16px;
          padding: 10px 36px;
          border-bottom: 1px solid #e1e1e1;

          .formlistIcon {
            background-color: $tertiary-border;
            width: 15px;
            height: 15px;
            font-size: 12px;
            padding: 1px;
            border-radius: 100%;
            color: $white;
            border-radius: 100%;
            color: $white;
          }
        }
      }
    }
  }

  .form_2x {
    flex: 1;
    height: auto;
    position: relative;

    .formImage {
      // border-radius: 25px;
      height: 993px;
      overflow: hidden;
    }

    .form_2x1 {
      position: absolute;
      bottom: 30px;
      left: auto;
      right: 30px;
      background-color: rgba(241, 241, 241, 0.5);
      border-radius: 20px;
      padding: 20px;
      color: $black;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 12px;
      text-align: center;
      align-items: center;

      :nth-child(1) {
        font-size: 16px;
        letter-spacing: 0.14px;
        line-height: 13px;
        font-weight: 400;
      }
      :nth-child(2) {
        font-size: 20px;
        letter-spacing: 0.14px;
        line-height: 13px;
        font-weight: 700;
      }

      :nth-child(3) {
        font-size: 16px;
        letter-spacing: 0.14px;
        line-height: 13px;
        font-weight: 500;
      }

      :nth-child(4) {
        font-size: 12px;
        letter-spacing: 0.25px;
        background-color: $secondary;
        border-radius: 10px;
        padding: 5px 10px;
        width: fit-content;
      }
      :nth-child(5) {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.14px;
        line-height: 13px;
      }
      :nth-child(6) {
        font-size: 12px;
        letter-spacing: 0.25px;
        background-color: $secondary;
        border-radius: 10px;
        width: fit-content;
        padding: 5px 10px;
      }
      :nth-child(7) {
        background-color: $primary;
        color: $white;
        border-radius: 50px;
        font-size: 15px;
        font-weight: 600;
        border: none;
        width: 100%;
        padding: 10px 40px;
      }
    }
  }

  .formsmothdrop {
    position: absolute;
    top: 40px;
    background-color: $white;
    z-index: 10;
    width: 100%;
    border-radius: 10px;
    padding: 5px 15px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-shadow: $medium-shadow;
  }
}

@media screen and (max-width: 868px) {
  .formContainer {
    flex-direction: column;

    .form_1x {
      width: 100%;

      .form_x2 {
        .formtheme_xx1 {
          .formDropdown_x1 {
            max-width: 95%;
          }
        }
      }
    }

    .form_2x {
      height: 900px;

      .form_2x1 {
        padding: 10px;
        gap: 8px;

        :nth-child(1) {
          font-size: 13px;
        }
        :nth-child(2) {
          font-size: 16px;
        }

        :nth-child(3) {
          font-size: 13px;
        }

        :nth-child(4) {
          font-size: 10px;
        }
        :nth-child(5) {
          font-size: 13px;
        }
        :nth-child(6) {
          font-size: 10px;
        }
        :nth-child(7) {
          font-size: 13px;
          padding: 8px 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .formmainContainer {
    padding: 20px 20px;
  }
  .formContainer {
    .form_1x {
      .form_x1 {
        padding: 0px 10px;
        .formuploadheading {
          justify-content: center;

          .formheading {
            font-size: 18px;
          }
        }
        .formuploadfile {
          height: 160px;
        }
      }

      .form_x2 {
        padding: 5px;
        margin-top: 30px;

        .formtheme_xx1 {
          margin-top: 10px;
          .formtheme {
            margin-left: 7px;
          }

          .formmoderndrop {
            width: 97%;

            .formDropdown_x1 {
              width: 100%;
              max-width: 100%;
            }
          }
        }

        .formtheme_xx2 {
          padding: 5px;
        }
      }

      .form_x3 {
        margin-top: 50px;
        .formlists {
          .formlist {
            padding: 10px;
          }
        }
      }
    }

    .form_2x {
      width: 100%;
      height: auto !important;

      .formImage {
        height: 446px;
      }
      .form_2x1 {
        padding: 20px 10px;
        gap: 5px;

        :nth-child(1) {
          font-size: 9px;
        }
        :nth-child(2) {
          font-size: 12;
        }

        :nth-child(3) {
          font-size: 9px;
        }

        :nth-child(4) {
          font-size: 7px;
        }
        :nth-child(5) {
          font-size: 9px;
        }
        :nth-child(6) {
          font-size: 7px;
        }
        :nth-child(7) {
          width: 150px;
          font-size: 12px;
          height: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
        }
      }
    }
  }
}
